"use client";

import React, { useState } from 'react'

import { ChakraProvider } from '@chakra-ui/react'
import { StackedImages } from './StackedImages'
// import { Button, Modal } from "flowbite-react"
import CreativeCredits from './CreativeCredits'

import './App.css'

type PTButtonProps = {
  onClick(): void;
  // name: string;
  children: React.ReactNode;
}

const PTButton: React.FunctionComponent<PTButtonProps> = ({ children, ...props }) => {
  const {onClick} = props;

  return(
    <div>
      {/* <h3>{name}</h3> */}
      <button onClick={onClick}>{children}</button>
    </div>
  );
};

function App() {
  const [open, setOpen] = useState(false);
  // const [modalPlacement, setModalPlacement] = useState("bottom-center");

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpen = () => {
    setOpen(true);
  }

  return (
    <div className="App">
      <div className="App-header">
        <h1>Porkey-Turkey &trade; Delicious Awesomeness</h1>
        <div className="App-header-subtitle">
          <p>is coming to Your Neighborhood</p>
          <p>Exclusively via Mobile/Online Orders!</p>
        </div>
        <ChakraProvider>
          <StackedImages visible={open}/>
        </ChakraProvider>
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <div className="App-info">
          <p>Franchise Opportunities are Available</p>
          <a
            className="App-link"
            href="https://porkey-turkey.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Porkey-Turkey
          </a>
        </div>


      <div 
        style={{
          textAlign: "center",
          display: "block",
          padding: 30,
          margin: "auto",
        }}
      >
          <PTButton onClick={handleOpen}>
            View Creative Credits
          </PTButton>

          <CreativeCredits isOpen={open} onClose={handleClose}>
          <>
            {/* <h2>Credits</h2>
            <h3>Turkey Cartoons</h3>
            <h4>
            <span>Image by <a href="https://pixabay.com/users/openclipart-vectors-30363/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1299176">OpenClipart-Vectors</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1299176">Pixabay</a></span>
<span>Image by <a href="https://pixabay.com/users/graphicmama-team-2641041/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1456057">GraphicMama-team</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1456057">Pixabay</a></span>
<span>Image by <a href="https://pixabay.com/users/schmidsi-104144/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1797557">Eva Schmidseder</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1797557">Pixabay</a></span>
<span>Image by <a href="https://pixabay.com/users/clker-free-vector-images-3736/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=48220">Clker-Free-Vector-Images</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=48220">Pixabay</a></span>
<span>Image by <a href="https://pixabay.com/users/clker-free-vector-images-3736/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=48562">Clker-Free-Vector-Images</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=48562">Pixabay</a></span>
</h4>
            <h3>Porkey Cartoons</h3> */}
          </>
          </CreativeCredits>
      </div>
    </div>
    </div>
  );
}

export default App;
