import React from "react";

const CreativeCredits = ({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean;
  onClose: React.MouseEventHandler<HTMLDivElement>;
  children: React.ReactNode;
}) => {
  if (!isOpen) return null;

  return (
    <div
      onClick={onClose}
      style={{
        //
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        // background: "rgba(0, 0, 0, 0.5)",
        background: "blue",
        display: "flex",
        alignItems: "bottom",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          //
          background: "white",
          height: "50%",
          width: "50%",
          margin: "auto",
          padding: "2%",
          border: "2px solid #000",
          borderRadius: "10px",
          boxShadow: "2px solid black",
          fontFamily: "Roboto",
          fontSize: "12px",
        }}
      >
        {children}
        <span style={{ fontSize: "24px" }}>Credits</span>
        <br />
        <span style={{ fontSize: "18px" }}>Porkey and Turkey Cartoons</span>
        <br /> 
        {/* <span style={{ fontSize: "12px", justifyContent: "center" }}> */}
          <table
            style={{ 
                marginLeft: "auto", 
                marginRight: "auto", 
                width: "100%",
                fontSize: "12px", 
                justifyContent: "center",
                textAlign: "center",
             }}
          >
            <tr>
                  Thanks to {" "}
                  <a href="https://pixabay.com/users/openclipart-vectors-30363/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1299176">
                    OpenClipart-Vectors 
                  </a>{" "}
                  {" "} from{" "}
                  <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1299176">
                    Pixabay
                  </a>
            </tr>
            <tr>
            Thanks to {" "}
              <a href="https://pixabay.com/users/graphicmama-team-2641041/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1456057">
                GraphicMama-team 
              </a>{" "}
              {" "} from{" "}
              <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1456057">
                Pixabay
              </a>
            </tr>
            <tr>
            Thanks to {" "}
              <a href="https://pixabay.com/users/schmidsi-104144/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1797557">
                Eva Schmidseder 
              </a>{" "}
              {" "} from{" "}
              <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1797557">
                Pixabay
              </a>
            </tr>
            <tr>
            Thanks to {" "}
              <a href="https://pixabay.com/users/clker-free-vector-images-3736/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=48220">
                Clker-Free-Vector-Images 
              </a>{" "}
              {" "} from{" "}
              <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=48220">
                Pixabay
              </a>
            </tr>
          </table>
        {/* </span> */}
        <br />
        <br />

        <span style={{ fontSize: "18px" }}>Development Team</span>
        <br />
        <span style={{ fontSize: "12px", justifyContent: "center" }}>
          <table style={{ marginLeft: "auto", marginRight: "auto" }}>
            <tr>
              <a href="https://www.globaldigitalapps.com">
              Global Digital Apps
              </a>
            </tr>
            
          </table>
        </span>
        <br />
        <br />

        <span style={{ fontSize: "18px" }}>Stack Animation Design</span>
        <br />
        <span style={{ fontSize: "12px", justifyContent: "center" }}>
          <table style={{ marginLeft: "auto", marginRight: "auto" }}>
            <tr>
              Special Thanks to: {" "}
              <a href="https://dev.to/calebpitan/creating-stacked-image-swap-animation-4kh2#what-we-need">
              Caleb Adepitan
              </a>
              <br/>
              <a href="https://codesandbox.io/p/sandbox/nervous-haze-10edyi">
              codesandbox.io
              </a>
            </tr>
          </table>
        </span>
        <br />
        <br />


        <br />
        <span style={{ fontSize: "12px", justifyContent: "center", fontWeight: "bolder" }}>
          <table style={{ marginLeft: "auto", marginRight: "auto" }}>
            <tr>
              Porkey-Turkey &trade; is a Members-owned Collective, part of {" "}
              <a href="https://www.virageteams.com">
              Virtual Agile Teams Ltd. 
              </a>

            </tr>
          </table>
        </span>

      </div>
    </div>
  );
};

export default CreativeCredits;
