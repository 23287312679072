import { Box, Flex } from "@chakra-ui/react";
import { useState, useMemo, useRef, useEffect } from "react";


const images = [
    "https://drive.google.com/uc?export=view&id=1Hk-uPAZK4gkqs131aoGY6rAVy0Ug9hPX",
    "https://drive.google.com/uc?export=view&id=1RhXsG2NojCRnH6e5IiACpqJviCDe2gYI",
    "https://drive.google.com/uc?export=view&id=1WVWLhlD66q_DEDaKk-5SxbNS9qryiKME",
    "https://drive.google.com/uc?export=view&id=1KWHPXg1wHOPyWt6gD9yKWkJG8Csmu62b",
    "https://drive.google.com/uc?export=view&id=1D0c3ivwYJQNFWpJ3g_t_VyEsRi-rGl9B",
    "https://drive.google.com/uc?export=view&id=1E2nG8HJg1-2VW0qyAV2tU0YO52v2w54H",
    "https://drive.google.com/uc?export=view&id=1AN8GW8kVOwrCVkP5qUMU4kLQ3mIry059",
    "https://drive.google.com/uc?export=view&id=13MDJ1T0FaqyEJ0uNZOUbGWMCiZgeQb9C",
    "https://drive.google.com/uc?export=view&id=1FibuVkJ2IbbNbQvuYlhsvOSdbu6mIXRg",
    "https://drive.google.com/uc?export=view&id=1CgxP5gvMq5_QelJcUP3MAkAZrBbxiGJE"
]




export const StackedImages = (props: {visible: boolean}) => {

    const { visible } = {...props};
    const [activeIndex, setActiveIndex] =  useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
    const size: number | null = useMemo( () => images.length, []); 
    const timer = useRef<NodeJS.Timeout | number>(-1);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const map = useMemo( () => {

        const map = new Map<number, number>();
        const len = images.length;
        let i = len;

        if (len < activeIndex || activeIndex < 0) {
            throw new Error("Invalid index set for active index!");
        }

        while (i > 0) {
            map.set((activeIndex + len - i) % len, --i);
        }

        return map;
    }, [activeIndex]);

    useEffect(() => {

        const container = containerRef.current;
        if (!container) return;

        const handler = () => {
            const { width } = container.getBoundingClientRect();
            setContainerWidth(width);
        };

        handler();

        window.addEventListener("resize", handler);

        return () => window.removeEventListener("resize", handler);
    }, []);

    useEffect(() => {

        timer.current = setInterval(
            () => setActiveIndex( (cur) => (cur + 1) % size), 5000);

        return () => clearInterval(timer.current as number);

    }, [size]);

    if (visible) return null;

    return (

        <Flex justifyContent="center" alignItems="center" my="auto">
            <Box
                position="relative"
                width={{ base: 300, md: 500, xl: 800 }}
                height={{ base: 300, md: 500, xl: 800 }}
                ref={containerRef}
            >
                {images.map((image, i) => {
                    const factor = size - 1 - map.get(i)!;
                    const isPreviousActiveIndex = (activeIndex + size - 1) % size === i;

                    return (
                        <Box 
                            key={image}
                            top={0}
                            right={0 - 0.09 * factor * containerWidth}
                            borderRadius="lg"
                            position="absolute"
                            backgroundImage={`url(${image})`}
                            backgroundSize="contain"
                            backgroundRepeat="no-repeat"
                            width="inherit"
                            height="inherit"
                            transform={`scale(${1 - 0.09 * factor})`}
                            zIndex={map.get(i)}
                            boxShadow="15px 0 10px -3px rgba(0,0,0,0.2)"
                            transition={"z-index 0.6s ease, transform 0.6s ease".concat(
                                isPreviousActiveIndex ? ", right 0.3s ease" : ""
                            )}
                        />
                    );
                })}
            </Box>
        </Flex>
    );
}